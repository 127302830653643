<!-- =========================================================================================
    File Name: inventory.vue
    Description: Inventory table
    ----------------------------------------------------------------------------------------
    Item Name: KOZO - VueJS Dashboard
    Author: BN
========================================================================================== -->

<template>
  <div>
    <!-- INVENTORY LIST -->
    <div id="ag-grid-demo">
      <vx-card>
        <div class="row mb-5 border-bottom">
          <div class="col-6 mx-auto">
            <h4 class>Filter Inventory By Project</h4>
            <div class="row">
              <div class="col-8">
                <div>
                  <multiselect
                    v-model="project_value"
                    track-by="project_id"
                    label="project_name"
                    @input="
                      ({ project_id }) => (this.form.project_id = project_id)
                    "
                    :options="projects"
                    name="Project Name"
                    placeholder="Select Project"
                    :searchable="true"
                    :allow-empty="false"
                    open-direction="bottom"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">{{
                      option.project_name
                    }}</template>
                  </multiselect>
                  <p class="error-msg">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Project Name')"
                      >{{ errors.first("Project Name") }}</span
                    >
                  </p>
                </div>
              </div>

              <div class="col-4">
                <vs-button
                  color="primary"
                  class="mx-auto hover-btn"
                  type="filled"
                  @click.prevent="searchExp"
                  >Search</vs-button
                >
              </div>
            </div>
          </div>
        </div>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4 class>Inventory</h4>
          <div class="flex flex-wrap justify-end items-center">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div
              class="
                flex flex-wrap
                items-center
                justify-between
                ag-grid-table-actions-right
              "
            >
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
              <vx-tooltip text="Export Excel">
                <!-- Before Select Project -->
                <template v-if="!this.project_value.project_id">
                  <vs-button class="px-4" @click.prevent="selectProject">
                    <span class="d-inline-flex pr-5 text-white">Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </template>
                <!-- After Select Project -->
                <template v-if="this.project_value.project_id">
                  <a
                    :href="
                      excelurl +
                      `/v1/inventory/excel/download?organization_id=${organization_id}&token=${temp_token}&project_id=${this.project_value.project_id}`
                    "
                    target="_blank"
                  >
                    <vs-button class="px-4">
                      <span class="d-inline-flex pr-5 text-white">Export</span>
                      <img
                        src="@/assets/images/icons/export-excel.svg"
                        class="d-inline-flex"
                        height="20"
                        width="20"
                      />
                    </vs-button>
                  </a>
                </template>
              </vx-tooltip>
            </div>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowClicked="onRowDataChanged"
          :rowData="inventoryData"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :paginationPageSize="paginationPageSize"
          :pagination="true"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { BASEURL } from "@/config/index.js";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import InventoryService from "@/services/inventoryService.js";
import ProjectService from "@/services/projectService.js";
import deleteInventory from "./deleteInventory.vue";
import inventoryDate from "./inventoryDate.vue";
import editInventory from "./editInventory.vue";
import viewExpImg from "./viewExpImg.vue";
import moveToInventory from "./moveToInventory.vue";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import Datepicker from "vuejs-datepicker";
import PermissionVariables from "@/services/UserManagementVaraibles.js";

export default {
  components: {
    Datepicker,
    AgGridVue,
    DatetimePickerStartEnd,
    "switch-component": {
      template: `<span>KOZO</span>`,
    },
  },
  props: ["listType"],
  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      loginUserRole: window.localStorage.getItem("UserRole"),
      excelurl: BASEURL(),
      msg: "",
      totalPages: 0,
      overlayLoadingTemplate: null,
      inventoryData: [],
      User: "Select User",
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      form: {
        project_id: null,
      },
      projects: [],
      project_value: [],
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      contacts: contacts,
      columnDefs: [],
      organization_id: "",
    };
  },
  beforeMount() {
    this.organization_id = localStorage.getItem("OrganizationID");
    eventBus.$on("refreshInvenoryTableList", (e) => {
      console.log(this.currentPage, "fsfdsfds");
      this.getAllInventoryList(this.currentPage);
    });
    this.columnDefs = [
      {
        headerName: "Name",
        field: "expense_name",
        filter: false,
        width: 250,
        suppressSizeToFit: true,
      },
      {
        headerName: "Category",
        field: "category_name",
        filter: false,
        minWidth: 350,
      },
      {
        headerName: "Number of Qty.",
        field: "quantity",
        filter: false,
        width: 250,
      },
      {
        headerName: "Location Name",
        field: "city_name",
        filter: false,
        width: 200,
        sortable: false,
      },
      {
        headerName: "Edit",
        field: "inventory_id",
        filter: false,
        width: 120,
        cellRendererFramework: editInventory,
      },
      {
        headerName: "Images",
        field: "inventory_id",
        filter: false,
        width: 120,
        cellRendererFramework: viewExpImg,
      },
    ];
    // this.setColums();
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          // this.getAllInventoryList(val);
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    selectProject: function () {
      this.$vs.notify({
        title: "Select Project",
        iconPack: "feather",
        icon: "check_box",
        color: "warning",
      });
    },
    onRowDataChanged(e) {
      console.log("e :", e);
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    getAllInventoryList: function (currentPage) {
      let filters = {
        page: currentPage,
      };
      let id = this.project_value.project_id;
      InventoryService.getAllInventory(filters, id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.inventoryData = data.data;
            this.totalPages = data.pagination.last_page;
            // this.gridApi.sizeColumnsToFit();
          } else {
            this.$vs.notify({
              title: "No Data found for the Request",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProjectsList: function () {
      ProjectService.getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            if (
              !this.permissionPageAccessArry.includes(
                PermissionVariables.ViewOrganizationProjects
              ) &&
              this.loginUserRole != "Admin"
            ) {
              data.data.map((x) => {
                if (
                  this.permissionPageAccessArry.includes("21P" + x.project_id)
                ) {
                  this.projects.push(x);
                }
              });
            } else {
              this.projects = data.data;
            }
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchExp: function () {
      let isProjectSelect = Object.keys(this.project_value).length;
      // GET TEMP DATA API
      if (isProjectSelect > 0) {
        this.getAllInventoryList(this.currentPage);
      } else {
        this.$vs.notify({
          title: "Select Project",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }
    },
  },
  mounted() {
    // if (this.checkPermission(["department", "GET"])) {
    //   this.getDepartmentList(this.currentPage);
    // }
    this.getProjectsList();
    this.gridApi = this.gridOptions.api;
  },
};
</script>
