<template>
  <div>
    <span
      class="text-center table-edit-icon"
      @click="imageSliderModal()"
      v-if="this.allImages != '-'"
    >
      <i class="text-center material-icons">remove_red_eye</i>
    </span>
    <span>No Image</span>
    <!-- POPUP -->
    <div class>
      <vs-popup
        :id="'popmodal' + params.data.inventory_id"
        title="Images"
        :active.sync="popupActive"
      >
        <!-- v-if="challanDetails.length != 0" -->
        <swiper
          :options="swiperOption"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          :key="$vs.rtl"
          v-if="expImages.length > 0"
        >
          <swiper-slide
            :key="expImgKey"
            v-for="(expImg, expImgKey) in expImages"
          >
            <div class="col-12 position-relative">
              <div class="swiper-zoom-container">
                <img
                  class="responsive text-center"
                  :src="expImg + '?token=' + tempToken"
                  alt="banner"
                />
              </div>
              <div class="float_delete">
                <vs-button
                  color="primary"
                  class="mx-auto hover-btn"
                  type="filled"
                  @click.prevent="deleteImgPopup(expImgKey)"
                  >Delete</vs-button
                >
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div
            class="swiper-button-prev swiper-button-white"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next swiper-button-white"
            slot="button-next"
          ></div>
        </swiper>

        <!-- <div v-else>
          <h3>NO DATA FOUND</h3>
        </div>-->
      </vs-popup>
    </div>
    <div class="demo-alignment">
      <vs-popup
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Images"
        :active.sync="popupActiveImg"
      >
        <div class="text-center">
          <h5>Do you want to delete image?</h5>
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <vs-button
                color="success"
                type="filled"
                @click="deleteImg()"
                class="mr-3"
                >Yes</vs-button
              >

              <vs-button color="danger" type="filled" @click="privStatus()"
                >No</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import InventoryService from "@/services/inventoryService.js";

import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default Vue.extend({
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      tempToken: window.localStorage.getItem("TempToken"),
      images: [],
      challanDetails: {},
      popupActive: false,
      popupActiveImg: false,
      params: null,
      value: null,
      count: 0,
      imgIndex: null,
      allImages: "",
      expImagesStr: "",
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        zoom: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  watch: {},
  computed: {
    expImages() {
      return this.allImages.split(",");
    },
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodal" + this.params.data.inventory_id
    );
    _id.setAttribute("style", "display: none;");
  },
  beforeMount() {
    this.allImages = this.params.data.expense_image;
  },
  methods: {
    imageSliderModal: function () {
      this.popupActive = true;
    },
    showModal() {
      this.popupActive = true;
      let payload = {};
      let id = this.params.value;
      purchaseOrderService
        .getchallanDetails(payload, id)
        .then((response) => {
          const { data } = response;
          if (data && data.Status == true) {
            this.challanDetails = data.data;
            this.challanDetails = this.challanDetails.map((x) => {
              x["images"] = x.challan_image.split(",");
              return x;
            });
          } else {
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    deleteImgPopup: function (index) {
      this.popupActiveImg = true;
      this.popupActive = false;
      this.imgIndex = index;
      console.log(this.imgIndex, "ggdsf");
    },
    privStatus: function () {
      this.popupActiveImg = false;
      this.popupActive = true;
    },
    deleteImg: function () {
      this.expImages = this.expImages.splice(this.imgIndex, 1);
      this.submitForm();
      this.popupActiveImg = false;
      this.popupActive = true;
    },

    submitForm: function () {
      let id = this.params.data.inventory_id;
      let payload = {
        project_id: this.params.data.project_id,
        project_name: this.params.data.project_name,
        category_id: this.params.data.category_id,
        category_name: this.params.data.category_name,
        expense_name: this.params.data.expense_name,
        quantity: this.params.data.quantity,
        city_id: this.params.data.city_id,
        expense_image:
          this.expImages.toString() == "" || this.expImages.toString() == null
            ? "-"
            : this.expImages.toString(),
        inventory_active: this.params.data.inventory_active,
      };
      console.log(payload);
      InventoryService.editInventory(payload, id)
        .then((response) => {
          this.$vs.loading.close();
          this.submitStatus = false;
          const { data } = response;
          this.popupActive = false;

          if (data.Status == true || data.Status == "true") {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,

              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            setTimeout(() => {
              eventBus.$emit("refreshInvenoryTable", data);
            }, 1);
            this.clearForm();
          } else {
            this.$vs.loading.close();
            console.log("data :", data);
            this.$vs.notify({
              title: "Error!",
              text: data.Message,

              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.submitStatus = true;
          console.log("error :", error);
        });
    },
  },

  mounted() {
    // this.images.push(this.params.value);
  },
});
</script>
<style>
</style>
