
<template>
  <div>
    <span class="text-center table-edit-icon" @click="popupActive=true">
      <i class="text-center material-icons">move_to_inbox</i>
    </span>
    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalA'+params.data.inventory_id"
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Inventory Status"
        :active.sync="popupActive"
      >
        <div class="text-center">
          <h5>Do you want to move to Inventory?</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <vs-button
                color="success"
                type="filled"
                @click="onChangeCheckbox($event)"
                class="mr-3"
              >Yes</vs-button>

              <vs-button color="danger" type="filled" @click="privStatus()">No</vs-button>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";
// import departmentService from "@/services/departmentService.js";
import InventoryService from "@/services/inventoryService.js";

export default Vue.extend({
  data() {
    return {
      params: null,
      popupActive: false,
      form: {
        inventory_active: "4"
      },
      copy_inventory_active: null
    };
  },
  beforeMount() {},
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalA" + this.params.data.inventory_id
    );
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    onChangeCheckbox: function(e, value) {
      this.$vs.loading();
      let id = this.params.data.inventory_id;
      let payload = {
        project_id: this.params.data.project_id,
        project_name: this.params.data.project_name,
        category_id: this.params.data.category_id,
        category_name: this.params.data.category_name,
        expense_name: this.params.data.expense_name,
        quantity: this.params.data.quantity,
        city_id: this.params.data.city_id,
        city_name: this.params.data.city_name,
        expense_image: this.params.data.expense_image,
        inventory_active: this.params.data.inventory_active == "4" ? "1" : "4"
      };

      InventoryService.editInventory(payload, id)
        .then(response => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status) {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success"
            });
            setTimeout(() => {
              eventBus.$emit("refreshInvenoryTable", data);
            }, 1);
            this.popupActive = false;
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning"
            });
          }
        })
        .catch(err => {
          this.$vs.loading.close();
        });
    },
    privStatus: function() {
      this.popupActive = false;
      this.form.inventory_active = this.copy_inventory_active;
    }
  }
});
</script>