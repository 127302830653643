var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"dashboard-analytics"}},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('router-link',{attrs:{"to":"/apps/projects"}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftCircleIcon","svgClasses":"h-8 w-8"}})],1)],1),_c('div',{staticClass:"vx-col w-full"},[_c('vs-tabs',[(
            _vm.permissionPageAccessArry.includes(
              _vm.PermissionVariables.CreateInventory
            ) || _vm.loginUserRole == 'Admin'
          )?_c('vs-tab',{attrs:{"label":"Create Inventory","icon-pack":"feather","icon":"icon-box"}},[_c('div',{staticClass:"tab-text"},[_c('div',[_c('create-inventory')],1)])]):_vm._e(),(
            _vm.permissionPageAccessArry.includes(
              _vm.PermissionVariables.InventoryList
            ) || _vm.loginUserRole == 'Admin'
          )?_c('vs-tab',{attrs:{"label":"Inventory List","icon-pack":"feather","icon":"icon-box"}},[_c('div',[_c('inventory-list')],1)]):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }