
<template>
  <div>
    <span @click="()=> this.popupActive = true">{{date}}</span>

    <vs-popup
      :button-close-hidden="false"
      class="holamundo p-2"
      title="Department Status"
      :active.sync="popupActive"
    >
      <!-- <datepicker :inline="true" :minimumView="'day'" :maximumView="'month'" v-model="date"></datepicker> -->
      <flat-pickr :config="configdateTimePicker" v-model="date" />
    </vs-popup>
  </div>
</template>

<script>
import Vue from "vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Datepicker from "vuejs-datepicker";

export default Vue.extend({
  data() {
    return {
      date: "dd/mm/yyyy",
      popupActive: false,
      configdateTimePicker: {
        allowInput: true,
        inline: true
      }
    };
  },
  components: {
    Datepicker,
    flatPickr
  },
  beforeMount() {},
  beforeDestroy() {},
  methods: {}
});
</script>